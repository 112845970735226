/**
 *
 * Fetch data from API
 * -------------------
 *
 * @param endpoint api endpoint without '/'
 * @param method api method
 * @param payload data
 *
 * @returns Promise with data or null
 *
 */

const api = async (
  endpoint: string,
  method = "GET",
  body = {}
): Promise<any | null> => {
  const token = localStorage.getItem("token");

  const fetchObj: RequestInit = {
    method: method,
    credentials: "omit",
    cache: "no-cache",
    headers: new Headers({
      "Content-Type": "application/json",
      Accept: "application/json",
    }),
  };

  if (method.toUpperCase() !== "GET" && body) {
    fetchObj.body = JSON.stringify(body);
  }
  if (token) {
    fetchObj.headers = new Headers({
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
      Accept: "application/json",
    });
  }

  try {
    const response = await fetch(
      process.env.REACT_APP_API_ROUTE + endpoint,
      fetchObj
    );

    if (response.ok && response.status >= 200 && response.status < 204) {
      if (response.headers.get("x-pagination-total-count")) {
        const data = await response.json();
        return new Promise((resolve, reject) =>
          resolve({
            data,
            total: parseInt(
              response.headers.get("x-pagination-total-count") || "0"
            ),
          })
        );
      }
      return response.json();
    } else if (response.ok && response.status === 204) {
      return {};
    } else {
      // console.warn('API status error:', response);
      return { error: await response.json() };
      // throw new Error(response.statusText);
    }
  } catch (error) {
    console.error("API error:", error.message);
    // throw new Error(error);
    return {
      error: error.message,
    };
  }
};

/**
 *
 */
export default api;
